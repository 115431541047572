/* eslint-disable */
/* See ... 
  @: https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
  @: https://developers.hubspot.com/docs/api/events/tracking-code
*/
import axios from "axios";
import { globalHistory } from "@reach/router";
import { dev, LocalStorage, detect } from "@utils";
import { trackUtms, cookies, analyticsSessionId, analyticsSessionEmail } from "@analytics";
let loadTriggered = false;
let loadFormsTriggered = false;
const hasWindow = detect.browser().window;
export const hubspotId = detect.hubspotPortalId();
export const enabled = detect.hubspotEnabled();
export let active = enabled && hasWindow && window?.HubSpotConversations;
export let _hsq = enabled && hasWindow && window?._hsq || [];
export let _hsp = enabled && hasWindow && window?._hsp || [];
export const _hbspt = enabled && hasWindow && window?.hbspt;
const currentLocation = globalHistory.location;
let webhookEnabled = detect.zapierOn();
let refreshed = false;
let identified = false;
let webhookError = false;
const setStorageKeys = (value, domain) => {
  LocalStorage.set(
    "_tracking_consent",
    value,
    false,
    true
  );
  LocalStorage.setSession(
    "_tracking_consent",
    value,
    false,
  );
  LocalStorage.setCookie("_tracking_consent", value, domain);
  console.log("_tracking_consent", value);
}
const getStatus = () => {
  if (active) {
    return true;
  } else {
    const widgetStatus = hasWindow && window?.HubSpotConversations?.widget?.status();
    active = widgetStatus?.loaded || widgetStatus?.pending;
    return active;
  }
}
const _doNotTrackSet = cookies?.lyraAllowCookies === false || cookies?._tracking_consent === false || cookies?.lyraAllowCookies === "false" || cookies?._tracking_consent === "false";
// const hasNavigator = detect.browser().navigator;
// const _doNotTrack = (hasNavigator && (navigator?.doNotTrack === 1 || window?.doNotTrack || navigator?.doNotTrack || navigator?.msDoNotTrack)) || cookies?.lyraAllowCookies === false || cookies?.lyraAllowCookies === false || cookies?._tracking_consent === false;

/* Load Chat Widget */
export const loadWidget = email => {
  const _crm_email = email || cookies?._crm_email;
  const _crm_token = LocalStorage.get("_crm_token", true, false);
  // Load widget
  if (_crm_email && _crm_token) {
    window.hsConversationsSettings = {
      loadImmediately: false,
      enableWidgetCookieBanner: true,
      identificationEmail: _crm_email,
      identificationToken: _crm_token,
      // inlineEmbedSelector: '#hubspot-widget',
      // disableAttachment: false
    };
  } else {
    window.hsConversationsSettings = {
      loadImmediately: false,
      enableWidgetCookieBanner: true,
      // inlineEmbedSelector: '#hubspot-widget',
      // disableAttachment: false
    };
  }
  window.hsConversationsOnReady = [
    () => {
      window.HubSpotConversations.widget.load();
    },
  ];
  console.log("Loaded Hubspot");
  active = true;
  setTimeout(() => {
    showConsent();
    identify({ email: _crm_email });
  }, 444);
}
/* Load essential scripts */
export const load = (location, hubspotVisitorId, additionalDetails) => {
  loadTriggered = true;
  if (dev.isDevLog) {
      console.log("loadHubspot");
  }
  const { search } = location || currentLocation;
  if (dev.isDevLog) {
    console.log("Booting messenger");
  }
  if (active) {
    console.log("Hubspot already loaded");
    return;
  }
  const urlParams = new URLSearchParams(search);
  /* Refresh the session and storage manually */
  const refreshSessions = urlParams.get("refresh") === "tokens" || urlParams.get("refresh") === "user" || urlParams.get("reset") === "user";
  if (refreshSessions && !refreshed) {
    // Remove cookie storage
    LocalStorage.removeCookie("_crm_id");
    LocalStorage.removeCookie("_crm_token");
    LocalStorage.removeCookie("_crm_email");
    LocalStorage.removeCookie("_submitted");
    LocalStorage.removeCookie("_leadSubmission");
    LocalStorage.removeCookie("_recaptcha");
    LocalStorage.removeCookie("ajs_user_id");
    LocalStorage.removeCookie("ajs_anonymous_id");
    LocalStorage.removeCookie("_grecaptcha");
    //Remove session storage
    LocalStorage.removeSession("_crm_id");
    LocalStorage.removeSession("_crm_token");
    LocalStorage.removeSession("_crm_email");
    LocalStorage.removeSession("_submitted");
    LocalStorage.removeSession("_leadSubmission");
    LocalStorage.removeSession("_recaptcha");
    LocalStorage.removeSession("ajs_user_id");
    LocalStorage.removeSession("ajs_anonymous_id");
    LocalStorage.removeSession("_grecaptcha");
    //Remove local storage
    LocalStorage.remove("_crm_id");
    LocalStorage.remove("_crm_token");
    LocalStorage.remove("_crm_email");
    LocalStorage.remove("_submitted");
    LocalStorage.remove("_leadSubmission");
    LocalStorage.remove("_recaptcha");
    LocalStorage.remove("ajs_user_id");
    LocalStorage.remove("ajs_anonymous_id");
    LocalStorage.remove("_grecaptcha");
    // shutdownMessenger();
    console.log("Cleared session");
    refreshed = true;
  }
  const _crm_email = additionalDetails?.email || cookies?._crm_email;

  // Load analytics and chat script
  if (enabled && !active && loadTriggered && hasWindow) {
    const w = window;
    const ic = w.hubspot;

    if (typeof ic === "function") {
      ic("reattach_activator");
      console.log("Updated Hubspot Instance");
    } else {
      const d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
          i.q.push(args);
      };
      w.hubspot = i;
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `//js.hs-scripts.com/${hubspotId}.js`;
      const x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      setTimeout(() => {
        active = getStatus();
        return loadWidget(_crm_email);
      }, 444);
    }
  }
};
const queueLoad = payload => {
  if (!loadTriggered) {
    const loaded = getStatus();
    setTimeout(() => {
      if (!active || !loaded) {
        loadTriggered = true;
        load(null, null, payload);
      }
    }, 444);
  }
}
export const loadForms = () => {
  loadFormsTriggered = true;
  if (dev.isDevLog) {
      console.log("loadHubspotForms");
  }
  // Load forms v3 script
  if (enabled && loadFormsTriggered && hasWindow && window && (!window.hbspt || !_hbspt)) {  
    const w = window;
    const ic = w.hbspt;

    if (typeof ic === "function") {
    ic("reattach_activator");
    console.log("Updated Hubspot Forms Instance");
    handleFormEvents();
    } else {
    const d = document;
    var i = function() {
        i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
        i.q.push(args);
    };
    w.hubspot = i;
    const s = d.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = `//js.hsforms.net/forms/embed/v3.js`;
    const x = d.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
    // Load listeners
    console.log("Loaded Hubspot Forms v3");
    handleFormEvents();
    return true;
  }
}
  return false;
};
/* Form loading */
export const loadForm = () => {
  // Load form
  window.hbspt = {
    loadImmediately: true,
    enableWidgetCookieBanner: true,
    disableInitialInputFocus: true,
    // inlineEmbedSelector: '#hubspot-widget',
    // disableAttachment: false
  };
  window.hsConversationsOnReady = [
    () => {
      window.hbspt.load();
    },
  ];
}
/* Messenger/Chat
  - https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
*/
export const open = () => {
  // Open widget
  const loaded = getStatus();
  if (loaded) {
    window.HubSpotConversations.widget.open();
  } else {
    queueLoad("open");
  }
}
export const message = () =>{
  // Start new message
  const loaded = getStatus();
  if (loaded) {
    window.HubSpotConversations.widget.refresh({ openToNewThread: true });
  } else {
    queueLoad("message");
  }
}
export const close = () => {
  // Open widget
  const loaded = getStatus();
  if (loaded) {
    window.HubSpotConversations.widget.close();
  } else {
    queueLoad("close");
  }
}
export const remove = () => {
  // Remove widget
  const loaded = getStatus();
  if (loaded) {
    window.HubSpotConversations.widget.remove();
  } else {
    queueLoad("remove");
  }
}
export const clear = () => {
  // Clear widget
  const loaded = getStatus();
  if (loaded) {
    window.HubSpotConversations.clear({resetWidget:true});
  } else {
    queueLoad("clear");
  }
}
export const refresh = () => {
  const loaded = getStatus();
  // Refresh widget
  if (loaded && !refreshed) {
    window.HubSpotConversations.widget.refresh();
    refreshed = true;
  } else {
    queueLoad("refresh");
  }
}
/* Identity and Events 
  - https://developers.hubspot.com/docs/api/events/tracking-code
*/
export const visitor = () => {
  const hubspotVisitor = analyticsSessionId();
  return hubspotVisitor;
}

export const track = (event, additionalData) => {
  // Track an event
  const loaded = getStatus();
  if (dev.isDevLog) {
    console.log("trackEvent", { event, additionalData });
  }
  if (loaded) {
    if (_hsq) {
      _hsq.push(["trackCustomBehavioralEvent",{
        name: event,
        properties: {
          ...additionalData,
      }
    }]);
    } else {
      loadHubspot({ ...additionalData, ...trackUtms?.track, ...trackUtms?.custom});
      setTimeout(() => {
        if (_hsq) {
          _hsq.push(["trackCustomBehavioralEvent",{
            name: event,
            properties: {
              ...additionalData,
          }
        }]);
          if (dev.isDevLog) {
            console.log("2nd try after fail trackEvent", { event, additionalData });
          }
        }
      }, 1999);
    }
  } else {
    queueLoad({hook: "track", data});
  }
}
export const page = () => {
  // Track a page load
  const loaded = getStatus();
  if (dev.isDevLog) {
    console.log("trackPageView");
  }
  if (loaded) {
    if (_hsq) {
      _hsq.push(["trackPageView"]);
    } else {
      loadHubspot({ ...trackUtms?.track, ...trackUtms?.custom});
      setTimeout(() => {
        if (_hsq) {
          _hsq.push(["trackPageView"]);
          if (dev.isDevLog) {
            console.log("2nd try after fail trackPageView", { event, additionalData });
          }
        }
      }, 1999);
    }
  } else {
    queueLoad("page");
  }
}
export const refreshHandler = () => {
  // Handler a refresh
  const loaded = getStatus();
  if (dev.isDevLog) {
    console.log("refreshPageHandlers");
  }
  if (loaded) {
    if (_hsq) {
      _hsq.push(["refreshPageHandlers"]);
    }
  } else {
    queueLoad("refreshHandler");
  }
}

/* Consent Management
  - https://developers.hubspot.com/docs/api/events/cookie-banner
*/
export const showConsent = () => {
  // Show consent banner
  const loaded = getStatus();
  if (dev.isDevLog) {
    console.log("showConsent");
  }
  if (_doNotTrackSet) {
    doNotTrack();
    console.log("Will not track");
  } else if (loaded) {
    if (_hsp) {
      console.log("_hsp: showBanner");
      _hsp.push(["showBanner"]);
      setTimeout(() => {
        _hsp.push(["addPrivacyConsentListener", function(consent) {
          console.log(`analytics: ${consent.categories.analytics}`);
          console.log(`advertisement: ${consent.categories.advertisement}`);
          console.log(`functionality: ${consent.categories.functionality}`);
      }]);
      }, 1999);
    }
  } else {
    console.log("queueLoad: showConsent");
    queueLoad("showConsent");
    setTimeout(() => {
      if (_hsp) {
        console.log("_hsp_timeout: showBanner");
        _hsp.push(["showBanner"]);
        _hsp.push(["addPrivacyConsentListener", function(consent) {
          console.log(`analytics: ${consent.categories.analytics}`);
          console.log(`advertisement: ${consent.categories.advertisement}`);
          console.log(`functionality: ${consent.categories.functionality}`);
        }]);
      }
    }, 1999);
  }
}
export const doNotTrack = () => {
  // Show consent banner
  const loaded = getStatus();
  if (dev.isDevLog) {
    console.log("doNotTrack");
  }
  if (loaded) {
    if (_hsq && _hsp) {
      _hsq.push(["doNotTrack"]);
      _hsp.push(["revokeCookieConsent"]);
      setStorageKeys(false, window?.location?.hostname);
    }
  } else {
    queueLoad("doNotTrack");
  }
}
export const consentStatus = () => {
  // Show consent banner
  const loaded = getStatus();
  if (dev.isDevLog) {
    console.log("consentStatus");
  }
  if (loaded) {
    if (_hsp) {
      _hsp.push(["addPrivacyConsentListener", function(consent) {
        const analytics = `analytics: ${consent.categories.analytics}`; 
        const advertisement = `advertisement: ${consent.categories.advertisement}`; 
        const functionality = `functionality: ${consent.categories.functionality}`; 
        return {analytics, advertisement, functionality};
      }]);
    }
  } else {
    queueLoad("consentStatus");
  }
}
// Hubspot form listeners
// https://legacydocs.hubspot.com/global-form-events
export const handleFormEvents = (formId) => {
  const loaded = getStatus();
  if (loaded) {
    window?.addEventListener('message', _event => {
    if(_event?.data?.type === 'hsFormCallback' && _event?.data?.eventName === 'onBeforeFormInit') {
      console.log(`Form ${formId || _event?.data?.id} initialized`);
    }
    });
    window?.addEventListener('message', _event => {
    if(_event?.data?.type === 'hsFormCallback' && _event?.data?.eventName === 'onFormReady') {
      console.log(`Form ${formId || _event?.data?.id} ready for use`);
    }
    });
    window?.addEventListener('message', _event => {
      if(_event?.data?.type === 'hsFormCallback' && _event?.data?.eventName === 'onBeforeFormSubmit') {
        // const _payload = _event?.data;
        // const submission = _payload && Object.fromEntries(_payload?.data);
        console.log(`Form ${formId || _event?.data?.id} is being submitted`);
      }
    });
    window?.addEventListener('message', _event => {
      if(_event?.data?.type === 'hsFormCallback' && _event?.data?.eventName === 'onFormSubmit') {
        // const _payload = _event?.data;
        // const submission = _payload?.data && _payload?.data[0] && Object.fromEntries(_payload?.data);
        console.log(`Processing form ${formId || _event?.data?.id} submission`);
      }
    });
  } else {
    queueLoad("handleFormEvents");
    setTimeout(() => {
      handleFormEvents();
    }, 444);
  }
};

// Identification
const getHubspotUserToken = async data => {
  const hadWebookError = webhookError === true || LocalStorage.getSession(
    "webhookError",
  );
  const lyra_user = cookies?.lyra_user;
  const isAuth = lyra_user?.email && lyra_user?.firstName && lyra_user?.lastName && lyra_user?.picture && detect.isLyraUserId(lyra_user?.id) && detect.isLyraUserId(lyra_user?.account?.id) && (lyra_user?.primaryUse === 'PERMIT_PACKAGES' || lyra_user?.primaryUse === 'PROPOSALS') && (lyra_user?.account?.type === 'INSTALLATION_COMPANY' || lyra_user?.account?.type === 'HOMEOWNER' || lyra_user?.account?.type === 'DESIGN_COMPANY');

  function handleError(error) {
    console.log("handleError", error);
    webhookError = true;
    webhookEnabled = false;
    identified = true;
    return cookies?.messagesUtk || cookies?._hubspot_utk;
  };
  if (hadWebookError || identified || cookies?.messagesUtk) {
    return cookies?.messagesUtk || cookies?._hubspot_utk;
  }
  webhookEnabled = hadWebookError ? false : detect.zapierOn();
  const _crm_email = data?.email || cookies?._crm_email || analyticsSessionEmail();
  if (!hadWebookError && !identified && webhookEnabled && isAuth) {
    const payload = {
      email: _crm_email,
      name: data?.name || `${cookies?.lyra_user?.firstName} ${cookies?.lyra_user?.lastName}`
    }
    const token = await axios
        .post(`${detect.zapierUrl()}/hubspot-identify`, payload)
          .then(results => {
            window.hsConversationsSettings = {
              identificationEmail: _crm_email,
              identificationToken: results,
              loadImmediately: false,
              enableWidgetCookieBanner: true,
            };
            _hsq.push(["trackPageView"]);
            window.HubSpotConversations.widget.refresh();
            LocalStorage.set("_crm_email", _crm_email);
            LocalStorage.setCookie("_crm_email", _crm_email);
            LocalStorage.set("_crm_token", cookies?.messagesUtk || cookies?._hubspot_utk, true, false);
            identified = true;
            console.log("[getHubspotUserToken]: token...", cookies?.messagesUtk);
            return cookies?.messagesUtk;
          }).catch(error => {
            LocalStorage.setSession(
              "webhookError",
              true,
              false,
            );
            webhookError = true;
            webhookEnabled = false;
            identified = true;
            console.error("[getHubspotUserToken]: Handling error...");
            return handleError(error);
          });
    return token;
  }
}
export const userToken = email => {
  const hubspotUserToken = cookies?._crm_token || getHubspotUserToken({ email: email || analyticsSessionEmail() });
  const _utk = cookies?._hubspot_utk; // Tracking GUID - Contains opaque GUID to represent current visitor
  const _hsq = cookies?._hsq; // Main Hubspot cookie for tracking visitors - domain, hubspotutk, initial timestamp, last timestamp, current timestamp, session number
  const token = hubspotUserToken || _utk || _hsq;
  return token;
};
export const identify = data => {
  // Identify a user or visitor
  const loaded = getStatus();
  if (loaded) {
    const _crm_email =  data?.email || cookies?._crm_email || analyticsSessionEmail();
    const _crm_id =  data?.id || data?.userId || cookies?._crm_id;
    const id = _crm_id || analyticsSessionId();
    if (_crm_email) {
      console.log("data?.email", data?.email);
      console.log("cookies?._crm_email", cookies?._crm_email);
      console.log("analyticsSessionEmail()", analyticsSessionEmail());
      // LocalStorage.set("_crm_email", _crm_email);
      // LocalStorage.setCookie("_crm_email", _crm_email);
    }
    const token = userToken(_crm_email);
    if (_hsq && token) {
      const hubspotUser = _hsq.push(["identify",{
        email: _crm_email,
        id,
      }]);
      _hsq.push(["trackPageView"]);
      setTimeout( () => {
        refresh();
      }, 444);
      return hubspotUser;
    } else {
      setTimeout(() => {
        if (_hsq) {
          const hubspotUser = _hsq.push(["identify",{
            email: _crm_email,
            id,
          }]);
          _hsq.push(["trackPageView"]);
          setTimeout( () => {
            refresh();
          }, 444);
          return hubspotUser;
        }
      }, 1999);
    } 
  } else {
    queueLoad({hook: "identify",data});
  }
}

// Newsletter signup via hubspot form
export const newsletterSignup = data => {
  webhookEnabled = detect.zapierOn();
  if (webhookEnabled) {
    axios
      .post(detect.newsletter().function, data)
        .then(results => {
          console.log("results", results);
          return results;
        }).catch(error => {
          console.error("Handling error...", error);
          webhookEnabled = false;
          return error;
        });
  }
  return "Local only";
}
