// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("/opt/build/repo/node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("/opt/build/repo/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-pricing-js": () => import("/opt/build/repo/src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-pricing-es-js": () => import("/opt/build/repo/src/templates/pricing_es.js" /* webpackChunkName: "component---src-templates-pricing-es-js" */),
  "component---src-templates-contact-es-js": () => import("/opt/build/repo/src/templates/contact_es.js" /* webpackChunkName: "component---src-templates-contact-es-js" */),
  "component---src-templates-design-es-js": () => import("/opt/build/repo/src/templates/design_es.js" /* webpackChunkName: "component---src-templates-design-es-js" */),
  "component---src-templates-permits-js": () => import("/opt/build/repo/src/templates/permits.js" /* webpackChunkName: "component---src-templates-permits-js" */),
  "component---src-templates-permits-es-js": () => import("/opt/build/repo/src/templates/permits_es.js" /* webpackChunkName: "component---src-templates-permits-es-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-es-js": () => import("/opt/build/repo/src/templates/page_es.js" /* webpackChunkName: "component---src-templates-page-es-js" */),
  "component---src-templates-manufacturers-js": () => import("/opt/build/repo/src/templates/manufacturers.js" /* webpackChunkName: "component---src-templates-manufacturers-js" */),
  "component---src-pages-index-es-js": () => import("/opt/build/repo/src/pages/index_es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-templates-contact-js": () => import("/opt/build/repo/src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-about-es-js": () => import("/opt/build/repo/src/templates/about_es.js" /* webpackChunkName: "component---src-templates-about-es-js" */),
  "component---src-templates-manufacturers-es-js": () => import("/opt/build/repo/src/templates/manufacturers_es.js" /* webpackChunkName: "component---src-templates-manufacturers-es-js" */),
  "component---src-templates-onboard-js": () => import("/opt/build/repo/src/templates/onboard.js" /* webpackChunkName: "component---src-templates-onboard-js" */),
  "component---src-templates-design-js": () => import("/opt/build/repo/src/templates/design.js" /* webpackChunkName: "component---src-templates-design-js" */),
  "component---src-templates-blog-single-js": () => import("/opt/build/repo/src/templates/blog/single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-blog-index-js": () => import("/opt/build/repo/src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-category-js": () => import("/opt/build/repo/src/templates/blog/category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("/opt/build/repo/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */)
}

