/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
import React, {
  useRef,
  useEffect,
  useState,
  Fragment,
  useContext,
} from "react";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import axios from "axios";
import math from "mathjs";
import { RichText as PrismicRichText } from "prismic-reactjs";
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { useCookies } from "react-cookie";
import classNames from "classnames";
import validator from "validator";
import { Transition, Popover } from "@headlessui/react";
import { canUseDOM, analyticsActive, huntVerify, trackUtms } from "@analytics";
import {
  handleFormEvents,
  enabled as hubspotEnabled,
  active as hubspotActive,
  track,
  refresh,
  open as openChat,
} from "@hubspot";
import { HeaderContext } from "@context";
import { detect, dev, zap, LocalStorage } from "@utils";
import { Button, Icon, RichText, Image } from "@ui";
import { Container } from "@layout";
import { RandomShapes } from "@decoration";
import { ShapesPatternRepeat } from "@svg";
import { DefaultRoles, DefaultLocations } from "@contact";
import { PrismicLink } from "@utilities";

const TEST_MODE = "success";
const TEST_TYPE = "subscribed";
const MAX_VAL = Number(process.env.GATSBY_FORMS_VOLUME_MAX_VAL) || 10001;
const MIN_VAL = Number(process.env.GATSBY_FORMS_VOLUME_MIN_VAL) || 20;
const MEDIAN_VAL_LOW =
  Number(process.env.GATSBY_FORMS_VOLUME_MEDIAN_VAL_LOW) || 120;
const MEDIAN_VAL_HIGH =
  Number(process.env.GATSBY_FORMS_VOLUME_MEDIAN_VAL_HIGH) || 1200;
const withValueLimit = ({ floatValue }) =>
  floatValue > 0 && floatValue <= MAX_VAL;
const volumeMessage = `Enter an accurate number from ${MIN_VAL.toLocaleString(
  "en-US"
)} to ${MAX_VAL.toLocaleString("en-US")
  .toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

const homeownerMessage =
  "<a href='https://go.lyra.solar/diy'>Click here to learn more about using Lyra</a>";
const equation = "-12.75 * log(y) + 107.04";
const fixed = value => math.eval(equation.replace("y", value)).toFixed(2);
const nameArray = name => name.split(" ");
// Testing locally
const localSubmit = zap(TEST_MODE, TEST_TYPE);

const ExtendedEmailSignup = React.memo(
  ({
    icon,
    heading,
    subtitle,
    listId,
    buttonText,
    buttonColor,
    successMessage,
    failureMessage,
    volumeLowTitle,
    volumeLowSubtitle,
    volumeTooltip,
    locationsTooltip,
    backgroundImageUrl,
    circleTop,
    noShapes,
    trackingEvent,
    promoCode,
    promoMemo,
    promoReferral,
    recaptcha: recaptchaMode,
    requirePhoneNumber,
    showPhoneNumber,
    collectVolume,
    fields,
    bleed,
    onboarding,
    uid,
    showMessengerButton,
    gradient,
    formId,
    formUrl,
    webhookUrl,
    embedMethod,
    formHeight,
  }) => {
    // eslint-disable-next-line no-unused-vars
    const {
      setNotifications,
      disabledClientTracking,
      allowHunting,
      allowTracking,
      hubspotVisitorId,
      setHubspotVisitorId,
      currentLocation,
      leadSubmitted,
      leadSubmission,
      setLeadHasSubmitted,
      setLeadSubmission,
      isMaintenance,
      isOnboarding,
      isMobile,
      enforceLang,
    } = useContext(HeaderContext);
    const { hostname, search } = currentLocation;
    const bgColor =
      gradient && gradient !== "light"
        ? `gradient-${gradient}`
        : "gradient-blue";
    const hubspotLocalActive =
      hubspotEnabled && canUseDOM && window && !!hubspotVisitorId;
    const serverSideTrackingSwitch = detect.segmentEnabled();
    const serverSideTrackingOn =
      serverSideTrackingSwitch &&
      (!hubspotActive ||
        !hubspotLocalActive ||
        (!analyticsActive || disabledClientTracking));
    const zapierOn = detect.zapierOn();
    const recaptchaKey = detect.recaptchaKey();
    const recaptcha = recaptchaMode === true;
    const recaptchaRef = React.createRef();
    let recaptchaValue;
    const emptyRecaptcha = value => {
      if (value === null || value === "" || value === undefined) return true;
      return false;
    };
    const langVolMsg = enforceLang
      ? "El compromiso mínimo anual es"
      : "Minimum annual commitment is";
    const volumeLowMessage = `${langVolMsg} ${MIN_VAL.toLocaleString("en-US")
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/year`;
    const zapierFunctionUrl = detect.zapierUrl();
    const initialCompanyGivenId = `${Math.floor(
      100000 + Math.random() * 9000000000000000
    )}`;
    const serverUserId = Math.floor(100000 + Math.random() * 9000000000000000);
    // Handle test lead submissions for developers and testing
    const isTestMode = detect.leadFormMode();
    const testLeadFullName = nameArray(`Test ${serverUserId}`);
    const testLeadFirstName = testLeadFullName[0];
    const testLeadLastName = `Test-${testLeadFullName[1]}`;
    const testleadFormUserType = {
      value: (fields && fields[0]?.value) || "Installer",
      label: (fields && fields[0]?.label) || "Installer",
    };
    const testleadFormLocations = [
      {
        value: DefaultLocations[5]?.value,
        label: DefaultLocations[5]?.label,
        territory: DefaultLocations[5]?.territory,
        contiguous: DefaultLocations[5]?.contiguous,
        supported: DefaultLocations[5]?.supported,
      },
      {
        value: DefaultLocations[45]?.value,
        label: DefaultLocations[45]?.label,
        territory: DefaultLocations[45]?.territory,
        contiguous: DefaultLocations[45]?.contiguous,
        supported: DefaultLocations[45]?.supported,
      },
    ];
    const [isTestLead] = useState(isTestMode);
    const [testLeadFormData] = useState(
      isTestLead && {
        name: isTestLead ? `Test ${serverUserId}` : "",
        firstName: isTestLead ? testLeadFirstName : "",
        lastName: isTestLead ? testLeadLastName : "",
        email: isTestLead ? `mktg-test+test-${serverUserId}@lyrasolar.com` : "",
        phone: isTestLead ? "9709079977" : null,
        company: isTestLead ? "Lyra Solar (main test)" : "",
        packageVolume: 20,
        userType: isTestLead ? testleadFormUserType : null,
        locations: isTestLead ? testleadFormLocations : [],
      }
    );
    // Handle onboarding new users to existing accounts
    const [isLeadOnboarding] = useState(isOnboarding && onboarding);
    const urlParams = new URLSearchParams(search);
    const preLoadedLead =
      urlParams.get("cid") &&
      urlParams.get("name") &&
      urlParams.get("email") &&
      urlParams.get("type") &&
      urlParams.get("company");
    const preLoadedLeadCompanyId = preLoadedLead && urlParams.get("cid");
    const preLoadedLeadFirstName = preLoadedLead && urlParams.get("name")[0];
    const preLoadedLeadLastName = preLoadedLead && urlParams.get("name")[1];
    const preLoadedLeadName = preLoadedLead && urlParams.get("name");
    const preLoadedLeadEmail = preLoadedLead && urlParams.get("email");
    const preLoadedLeadPhone =
      preLoadedLead && urlParams.get("phone").replace(+1, null);
    const preLoadedLeadCompany = preLoadedLead && urlParams.get("company");
    const [isLeadPreLoaded] = useState(isLeadOnboarding && preLoadedLead);
    const [preLoadedLeadFormData] = useState(
      isLeadOnboarding &&
        isLeadPreLoaded && {
          name: isLeadPreLoaded ? decodeURIComponent(preLoadedLeadName) : "",
          firstName: isLeadPreLoaded ? preLoadedLeadFirstName : "",
          lastName: isLeadPreLoaded ? preLoadedLeadLastName : "",
          email: isLeadPreLoaded ? decodeURIComponent(preLoadedLeadEmail) : "",
          phone: isLeadPreLoaded
            ? decodeURIComponent(preLoadedLeadPhone).replace(+1, null)
            : null,
          company: isLeadPreLoaded
            ? decodeURIComponent(preLoadedLeadCompany)
            : "",
        }
    );
    const [cookies] = useCookies(["messagesUtk"]);
    // State management
    const [hubspotId] = useState(hubspotVisitorId || cookies?._crm_id);
    const [companyId] = useState(preLoadedLeadCompanyId);
    const [initialCompanyId] = useState(initialCompanyGivenId);
    const [phoneRequired] = useState(requirePhoneNumber);
    const [showPhoneField] = useState(showPhoneNumber || requirePhoneNumber);
    const [loading, setLoading] = useState(false);
    const [serverSideTracking, setServerSideTracking] = useState(
      serverSideTrackingOn
    );
    const [bgColorMain, setBgColorMain] = useState(buttonColor);
    const [fillLeadForm] = useState(
      (isLeadOnboarding && preLoadedLeadFormData) ||
        (!isLeadOnboarding && testLeadFormData)
    );
    const [wasSuccessful, setWasSuccessful] = useState(false);
    const [completionMessage, setCompletionMessage] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [companyError, setCompanyError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [roleError, setRoleError] = useState(null);
    const [roleErrorMsg] = useState(
      bleed || isLeadOnboarding
        ? "Please select your role"
        : "Please select your company's role"
    );
    const [locationsError, setLocationsError] = useState(null);
    const [volume, setVolume] = useState(0);
    const [volumeError, setVolumeError] = useState(null);
    const [isHomeowner, setIsHomeowner] = useState(null);
    const [volumeTier, setVolumeTier] = useState("Default");
    const [lowVolumeTier, setLowVolumeTier] = useState(null);
    const [waitlist, setWaitlist] = useState(null);
    const [huntBypass, setHuntBypass] = useState(false);
    const [hunting, setHunting] = useState(emailError && !huntBypass);
    const [hunted, setHunted] = useState(null);
    const [plan, setPlan] = useState(0);
    const [spend, setSpend] = useState(0);
    // const [useMailchimp] = useState(willUseMailchimp);
    const [useZapier] = useState(zapierOn);
    const [wholeHealth, setWholeHealth] = useState(null);
    const [submittedUid, setSubmittedUid] = useState(null);
    const [hasSubmitted, setHasSubmitted] = useState(leadSubmitted);
    const [hasSubmission, setHasSubmission] = useState(leadSubmission);
    const [roleOptions] = useState(fields || DefaultRoles);
    const [selectedRole, setSelectedRole] = useState(
      hasSubmission?.userType || fillLeadForm.userType
    );
    const [locationOptions] = useState(
      DefaultLocations.filter(v => v.supported)
    ); // filter based on supported: true
    const [selectedLocations, setSelectedLocations] = useState(
      hasSubmission?.locations || fillLeadForm.locations
    );
    const [recaptchaEmpty, setRecaptchaEmpty] = useState(
      emptyRecaptcha(recaptchaValue)
    );
    const [recaptchaToken, setRecaptchaToken] = useState(recaptchaValue);

    const promoMaterial = promoCode &&
      promoMemo && {
        promo_code: promoCode,
        promo_memo: promoMemo,
      };
    const promoMReferralMaterial = promoReferral && {
      promo_referral: promoReferral,
    };
    const [formData, setFormData] = useState({
      email: hasSubmission?.email || fillLeadForm.email,
      phone: hasSubmission?.phone || fillLeadForm.phone,
      name: hasSubmission?.name || fillLeadForm.name,
      firstName: hasSubmission?.firstName || fillLeadForm.firstName,
      lastName: hasSubmission?.lastName || fillLeadForm.lastName,
      list_id: listId,
      company: hasSubmission?.company || fillLeadForm.company,
      packageVolume: hasSubmission?.packageVolume || fillLeadForm.packageVolume,
      userType: selectedRole,
      locations: selectedLocations,
      form_id: formId,
      form_url: formUrl,
      webhook_url: webhookUrl,
      form_height: formHeight || "801px",
      ...promoMaterial,
      ...promoMReferralMaterial,
    });
    const isWaitlisted = waitlist || !!lowVolumeTier;
    // const clearForm = () => {
    //   setFormData({ email: "", firstName: "", lastName: "", company: "" });
    // };
    // handle UTM params
    let { track: baseUtms, custom: customUtms } = trackUtms;
    baseUtms = {
      ...baseUtms,
      utm_term: baseUtms?.utm_term || "lead-form",
      utm_medium: baseUtms?.utm_medium || "website",
      utm_source: baseUtms?.utm_source || trackingEvent || "Website signup",
      utm_campaign:
        baseUtms?.utm_campaign || formData?.promo_referral || "Website",
      utm_content: baseUtms?.utm_content || hostname || "lyrasolar.com",
    };
    customUtms = {
      ...customUtms,
      utm_source: customUtms?.utm_source || trackingEvent || "Website signup",
      utm_campaign:
        customUtms?.utm_campaign || formData?.promo_referral || "Website",
      utm_content: customUtms?.utm_content || hostname || "lyrasolar.com",
      "UTM Content": customUtms?.utm_content || hostname || "lyrasolar.com",
      "UTM Source": customUtms?.utm_source || trackingEvent || "Website signup",
      "UTM Campaign":
        customUtms?.utm_campaign || formData?.promo_referral || "Website",
      "UTM Medium": customUtms?.utm_medium || "website",
      "UTM Term": customUtms?.utm_term || "lead-form",
    };
    const [baseUtm] = useState(baseUtms);
    const [customUtm] = useState(customUtms);
    /* Lead details being sent to Segment and Hubspot */
    const [hubspotLeadDetails, setHubspotLeadDetails] = useState({
      email: formData?.email,
      phone: formData?.phone,
      name: formData?.name,
      ...baseUtm,
    });
    const [hubspotLeadAttributes, setHubspotLeadAttributes] = useState({
      "Buying Role": "CHAMPION",
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      Source: trackingEvent || "Website signup",
      "Lead Source": trackingEvent || "Website signup",
      "Lead Form ID": formData?.form_id || formData?.list_id,
      "Lead Category": formData?.userType?.value,
      "Job Title": formData?.userType?.value,
      "Lead status": "Interested",
      promo_code: formData?.promo_code,
      promo_memo: formData?.promo_memo,
      promo_referral: formData?.promo_referral,
      "Referral URL": `https://lyrasolar.com/${formData?.promo_referral}`,
      "Company name": formData?.company,
      stayInTouch: true,
      "Website signup": true,
      Waitlist: isWaitlisted,
      "Estimated Annual Revenue": Math.floor(spend).toLocaleString(),
      "Estimated Annual Volume": volume,
      Markets: formData?.locations?.map(i => i.value).toString(),
      "User Role": isLeadOnboarding ? "USER" : "PERMIT_PACKAGES",
      ...customUtm,
    });
    const [hubspotCompanyDetails, setHubspotCompanyDetails] = useState({
      name: formData?.company,
      plan: `$${Math.floor(plan).toLocaleString()}/permit`,
      monthly_spend: Math.floor(spend / 12).toLocaleString(),
      industry: formData?.userType?.value,
    });
    const [hubspotCompanyAttributes, setHubspotCompanyAttributes] = useState({
      "Plan Name": `$${Math.floor(plan).toLocaleString()}/permit`,
      "Permit Volume - Annual": volume,
      "Volume Tier": volumeTier,
      waitlist: isWaitlisted,
      Source: trackingEvent || "Website signup",
      "Company industry": formData?.userType?.value,
      Markets: formData?.locations?.map(i => i.value).toString(),
    });

    /* Validate the formData to allow submission */
    const validVolume =
      formData.packageVolume &&
      formData.packageVolume !== undefined &&
      formData.packageVolume !== "" &&
      formData.packageVolume !== 0;
    const collectedVolume = collectVolume === true ? validVolume : true;
    const preventResubmission = hasSubmitted && !bleed && !isLeadOnboarding;
    const preventUpdates =
      (!!completionMessage && !!wasSuccessful && !!submittedUid) ||
      preventResubmission ||
      isMaintenance;
    const showRecaptcha =
      (recaptcha &&
        (!useZapier && (!formData.form_id || !formData.form_url))) ||
      ((bleed || isLeadOnboarding) && !hasSubmitted) ||
      (recaptcha && !isHomeowner && !preventResubmission);
    const formFilled =
      formData.name &&
      !validator.isEmpty(formData.name) &&
      formData.lastName &&
      !validator.isEmpty(formData.lastName) &&
      formData.email &&
      !validator.isEmpty(formData.email) &&
      formData.company &&
      !validator.isEmpty(formData.company) &&
      formData?.userType &&
      (formData?.locations &&
        formData?.locations[0] &&
        formData?.locations[0].value) &&
      phoneRequired
        ? formData.phone && !validator.isEmpty(formData.phone)
        : true && collectedVolume;
    const formErrors =
      !!hasError ||
      !!nameError ||
      !!phoneError ||
      !!emailError ||
      !!companyError ||
      !!locationsError ||
      (!!volumeError && !lowVolumeTier);
    const disabledButton =
      !formFilled ||
      formErrors ||
      loading ||
      hunting ||
      wasSuccessful ||
      preventResubmission ||
      isHomeowner ||
      (isLeadOnboarding && !isLeadPreLoaded);
    const waitlistTitle =
      volumeLowTitle || "Thank you for your interest in Lyra";
    const WaitlistSubtitle = () => {
      if (volumeLowSubtitle) {
        return <RichText content={volumeLowSubtitle} />;
      }
      return "You will be added to our waitlist";
    };
    // timeouts
    let testTimeout;
    let recpatchaTimeout;
    let hubspotTimeout;
    let serverTimeout;

    const handleBrowserFailure = message => {
      track("Lead form issue", {
        user_id: hubspotId || hubspotVisitorId,
        signupErrorMsg: message,
        ...hubspotLeadDetails,
        ...hubspotLeadAttributes,
      });
      clearTimeout(recpatchaTimeout);
    };

    /* Handle changes */
    // eslint-disable-next-line consistent-return
    // pricing algo to determine estimated monthly_spend
    const handleSpend = value => {
      if (value >= 3000) {
        setVolume(value);
        setPlan(35);
        setSpend(35 * value);
        return { plan: 35, spend: 35 * value };
        // eslint-disable-next-line no-else-return
      } else if (value >= 1500) {
        setVolume(value);
        setPlan(41);
        setSpend(41 * value);
        return { plan: 41, spend: 41 * value };
      } else if (value >= 500) {
        setVolume(value);
        setPlan(45);
        setSpend(45 * value);
        return { plan: 45, spend: 45 * value };
      } else if (value >= 200 && value <= 499) {
        setVolume(value);
        setPlan(47);
        setSpend(47 * value);
        return { plan: 45, spend: 45 * value };
      } else if (value >= 95 && value <= 199) {
        setVolume(value);
        setPlan(49);
        setSpend(49 * value);
        return { plan: 49, spend: 49 * value };
      } else if (value > 5 && value < 95 && value !== "" && value != null) {
        setVolume(value);
        setPlan(Number(fixed(value)));
        setSpend(Number(fixed(value)) * value);
        return {
          plan: Number(fixed(value)),
          spend: Number(fixed(value)) * value,
        };
      } else {
        setVolume(value);
        setPlan(85);
        setSpend(85 * value);
        return { plan: 85, spend: 85 * value };
      }
    };

    const handleChange = (field, value) => {
      /* Current supported utilities: packageVolume, company, name */
      setFormData({
        ...formData,
        [field]: value,
      });
      if (field === "packageVolume") {
        setHubspotLeadAttributes({
          ...hubspotLeadAttributes,
          "Est Permit Price": `$${Math.floor(
            handleSpend(value).plan
          ).toLocaleString()}`,
          "Estimated Annual Revenue": Math.floor(handleSpend(value).spend),
          "Estimated Annual Volume": value,
          waitlist: isWaitlisted,
        });
        setHubspotCompanyDetails({
          ...hubspotCompanyDetails,
          monthly_spend: Math.floor(handleSpend(value).spend / 12),
          plan: `$${Math.floor(
            handleSpend(value).plan
          ).toLocaleString()}/permit`,
        });
        setHubspotCompanyAttributes({
          ...hubspotCompanyAttributes,
          "Plan Name": `$${Math.floor(
            handleSpend(value).plan
          ).toLocaleString()}/permit`,
          "Permit Volume - Annual": value,
          "Volume Tier": volumeTier,
          waitlist: isWaitlisted,
        });
      } else if (field === "company") {
        setHubspotLeadAttributes({
          ...hubspotLeadAttributes,
          "Company name": value,
          waitlist: isWaitlisted,
        });
        setHubspotCompanyDetails({
          ...hubspotCompanyDetails,
          name: value,
        });
      } else if (field === "name") {
        const fullName = nameArray(value);
        const firstName = fullName[0];
        const lastName = fullName[1];
        setFormData({
          ...formData,
          [field]: value,
          firstName,
          lastName,
        });
        setHubspotLeadDetails({
          ...hubspotLeadDetails,
          [field]: value,
        });
        setHubspotLeadAttributes({
          ...hubspotLeadAttributes,
          firstName,
          lastName,
        });
      } else if (field === "email" || field === "phone") {
        setHubspotLeadDetails({
          ...hubspotLeadDetails,
          [field]: value,
        });
      } else if (field === "userType") {
        setSelectedRole(value);
        setHubspotLeadAttributes({
          ...hubspotLeadAttributes,
          "Lead Category": value.value,
          "Lead category": value.value,
          "Company position": value.value,
          waitlist: value.value === "Homeowner",
        });
        setHubspotCompanyAttributes({
          ...hubspotCompanyAttributes,
          "Company industry": value.value,
          waitlist: value.value === "Homeowner",
        });
        setHubspotCompanyDetails({
          ...hubspotCompanyDetails,
          industry: value.value,
        });
      } else if (field === "locations") {
        setSelectedLocations(value);
        setHubspotLeadAttributes({
          ...hubspotLeadAttributes,
          Locations: value.map(i => i.value).toString(),
        });
        setHubspotCompanyAttributes({
          ...hubspotCompanyAttributes,
          Markets: value.map(i => i.value).toString(),
        });
      } else {
        setHubspotLeadAttributes({
          ...hubspotLeadAttributes,
          waitlist: isWaitlisted,
          [field]: value,
        });
        setHubspotCompanyAttributes({
          ...hubspotCompanyAttributes,
          waitlist: isWaitlisted,
        });
      }
    };

    /* Handle outcomes/cases */
    const handleHubspot = () => {
      const verify =
        recaptchaToken ||
        recaptchaValue ||
        LocalStorage.get("_recaptcha", false) ||
        recaptchaRef.current;
      const payload = {
        form: formData,
        track: serverSideTracking,
        anonymousId: `${hubspotId || hubspotVisitorId}`,
        userId: `${hubspotId || hubspotVisitorId}`,
        companyId,
        initialCompanyId,
        onboarding: isLeadOnboarding,
        verify,
        lead: {
          details: hubspotLeadDetails,
          attributes: hubspotLeadAttributes,
          wholeHealth,
        },
        company: {
          details: hubspotCompanyDetails,
          attributes: hubspotCompanyAttributes,
        },
      };
      hubspotTimeout = setTimeout(() => {
        track("Website signup click", payload);
        refresh();
      }, 444);
    };

    const handleSuccess = () => {
      const success_signature = new Date().getTime();
      const submittedLeadId =
        submittedUid ||
        hubspotVisitorId ||
        hubspotId ||
        hubspotLeadDetails.user_id ||
        hubspotLeadDetails.external_id;
      LocalStorage.setCookie("_crm_id", submittedLeadId);
      LocalStorage.set("_crm_id", submittedLeadId, false);
      LocalStorage.set("_crm_email", hubspotLeadDetails.email, false);
      LocalStorage.setCookie("_crm_email", hubspotLeadDetails.email);
      LocalStorage.set("_leadSubmission", JSON.stringify(formData), false);
      LocalStorage.setCookie("_leadSubmission", JSON.stringify(formData));
      LocalStorage.set("_submitted", success_signature, false);
      LocalStorage.setCookie("_submitted", success_signature);
      LocalStorage.remove("_recaptcha");
      console.log("Success!");
      const successMessaging = isWaitlisted
        ? "You have been added to the waitlist and will hear from us via email."
        : PrismicRichText.asText(successMessage);
      if (dev.isTestLead) {
        console.log("Test Lead:", {
          hubspotActive: hubspotActive || !!hubspotId,
          hubspotLocalActive,
          hubspotId: hubspotId || hubspotVisitorId,
          user_id: submittedLeadId,
          verify: recaptchaToken || recaptchaValue,
        });
      }
      setLoading(false);
      setHunting(false);
      setEmailError(null);
      setHasError(false);
      setHuntBypass(false);
      setWasSuccessful(true);
      setHasSubmitted(!bleed && !isLeadOnboarding);
      setCompletionMessage(true);
      setLeadHasSubmitted(true);
      setHasSubmission(formData);
      setLeadSubmission(formData);
      setNotifications(prevState => ({
        ...prevState,
        [`notification-${success_signature}`]: {
          type: isWaitlisted ? "purple" : "black",
          content: successMessaging,
          time: 11111,
        },
      }));
      console.log("Registering your message...");
      setHubspotVisitorId(submittedLeadId);
      // we put a near 20 second delay on reloading hubspot from defaultlayout with the new user params - to avoid duplicates while we wait for the bg function to finish
      handleHubspot(wholeHealth);
    };

    const handleError = (event, error, message, status) => {
      const error_signature = new Date().getTime();
      setHunting(false);
      // setLoading(false);
      setHasError(true);
      handleBrowserFailure(message);
      console.error("handleError...", error, message, status);
      dev.error(`ExtEmail.handleError.${status}`, message, {
        form: formData,
        lead: hubspotLeadDetails,
        company: hubspotCompanyDetails,
        track: serverSideTracking,
        anonymousId: hubspotId || hubspotVisitorId,
      });
      const messageDetail = `\r\n Message Detail: ${status} - ${message}`;
      const failureMessageDetail =
        status === 409
          ? message
          : `${PrismicRichText.asText(failureMessage)} ${messageDetail}`;
      setNotifications(prevState => ({
        ...prevState,
        [`notification-${error_signature}`]: {
          type: "error",
          content: failureMessageDetail,
        },
      }));
      if (status !== 409) Sentry.captureException(error);
    };

    const handleServer = (event, health) => {
      const verify =
        recaptchaToken ||
        recaptchaValue ||
        LocalStorage.get("_recaptcha", false) ||
        recaptchaRef.current;
      const payload = {
        form: formData,
        track: serverSideTracking,
        anonymousId: `${hubspotId || hubspotVisitorId}`,
        userId: `${hubspotId || hubspotVisitorId}`,
        companyId,
        initialCompanyId,
        onboarding: isLeadOnboarding,
        verify,
        lead: {
          details: hubspotLeadDetails,
          attributes: hubspotLeadAttributes,
          health,
          wholeHealth,
        },
        company: {
          details: hubspotCompanyDetails,
          attributes: hubspotCompanyAttributes,
        },
      };
      serverTimeout = setTimeout(() => {
        // Local testing utility
        if (useZapier) {
          console.log(`Sending your message...`);
          axios
            .post(`${zapierFunctionUrl}/hubspot-subscribe`, payload)
            .then(results => {
              const { data } = results;
              setHubspotVisitorId(hubspotId);
              handleSuccess(data);
            })
            .catch(error => {
              const { status, statusCode, message } = error;
              setHasError(true);
              handleError(
                event,
                error,
                message,
                status || statusCode,
                wholeHealth
              );
            });
        } else {
          console.log(
            `Handling your message, but there is no direct submission [${useZapier}]...`
          );
          console.log(payload);
          console.log("This is a local submit", localSubmit);
          testTimeout = setTimeout(() => {
            // setHasError(!!localSubmit.error);
            // setCompletionMessage(!!localSubmit.uid);
            // setWasSuccessful(!!localSubmit.uid);
            if (localSubmit.statusText === "email_exists") {
              handleError(
                event,
                localSubmit.data,
                localSubmit.body.message,
                409
              );
            } else if (localSubmit.error) {
              handleError(
                event,
                localSubmit.data,
                localSubmit.body.message,
                409
              );
            } else {
              handleSuccess(localSubmit.data);
            }
          }, 222);
        }
      }, 333);
    };

    const handleHunting = (event, email) => {
      if (huntBypass && hunted) {
        setHunting(false);
        setEmailError(null);
        setHasError(false);
        setLoading(false);
        setHunted(email);
      } else {
        huntVerify(allowHunting, huntBypass, email)
          .then(beenHunted => {
            const {
              message,
              risky,
              status,
              result,
              bypass,
              block,
              score,
              smtp,
            } = beenHunted;
            setHuntBypass(bypass);
            setHunted(email);
            // under these conditions, block submission
            if (
              block === true ||
              status === "invalid" ||
              result === "undeliverable" ||
              score === 0 ||
              smtp === false
            ) {
              setEmailError(message);
              setHasError(true);
              setLoading(false);
              // under these conditions, show message but possibly allow next submission
            } else if (message && !risky) {
              setEmailError(message);
              setHasError(false);
              setLoading(false);
              // under these conditions, submit and move on
            } else if (
              (message === null || message === "") &&
              !risky &&
              !hasError
            ) {
              setEmailError(null);
              setHasError(false);
              setLoading(false);
              // All set, send to the server to handle the rest
              setWholeHealth({
                Lead_Email_Score: beenHunted?.score,
                Lead_Email_Risky: beenHunted?.risky || beenHunted?.block,
                Lead_Email_Webmail:
                  beenHunted?.webmail || beenHunted?.disposable,
                Lead_Email_Status: beenHunted?.status,
                Lead_Email_Result: beenHunted?.result,
                Lead_Email_Accept_All:
                  beenHunted?.accept_all || beenHunted?.status === "accept_all",
                Lead_Email_DNS:
                  beenHunted?.mx || beenHunted?.smtp || beenHunted?.smtpServer,
                Lead_Email_Recaptcha: recaptcha,
              });
              handleServer(event, beenHunted);
              // otherwise, show message but possibly allow next submission
            } else {
              setEmailError(message);
              setHasError(true);
              setLoading(false);
              // event.preventDefault();
            }
          })
          .catch(error => {
            const { message } = error;
            setHuntBypass(false);
            setHasError(false);
            setLoading(false);
            setEmailError(message);
            setHunted(email);
            // recaptchaRef.current = null;
            // setRecaptchaValue(null);
            Sentry.captureException(error);
            // event.preventDefault();
            handleBrowserFailure(message);
          });
        setHunting(false);
      }
    };

    /* Action Handlers */
    const handleEvent = (event, data) => {
      hubspotTimeout = setTimeout(() => {
        console.log("Mocking the server...");
        const verify =
          recaptchaToken ||
          recaptchaValue ||
          LocalStorage.get("_recaptcha", false) ||
          recaptchaRef.current;
        const payload = {
          form: formData,
          track: serverSideTracking,
          anonymousId: `${hubspotId || hubspotVisitorId}`,
          userId: `${hubspotId || hubspotVisitorId}`,
          companyId,
          initialCompanyId,
          verify,
          lead: {
            details: hubspotLeadDetails,
            attributes: hubspotLeadAttributes,
          },
          company: {
            details: hubspotCompanyDetails,
            attributes: hubspotCompanyAttributes,
          },
        };
        const messagePayload = {
          message: "Successfully subscribed - local event",
          status: 200,
          statusCode: 200,
          statusText: "TEST",
        };
        if (dev.isTestLead) {
          console.log("Local: handleEvent", data, payload);
        }
        if (
          allowHunting &&
          !huntBypass &&
          validator.isEmail(payload?.form?.email)
        ) {
          const emailHasError = !!emailError;
          const emailComplete = !!payload?.form?.email && !emailHasError;
          // const emailSame = emailComplete && formData.email === formData.email;
          // const huntedEmailMatches = hunted === emailSame;
          if (emailComplete && !hunting) {
            console.log("Verifying contact...");
            setHunting(true);
            handleHunting(event, formData.email);
          } else {
            console.log("Skipping verification...");
            handleSuccess(messagePayload);
          }
        } else {
          console.log("Not verified...");
          handleSuccess(messagePayload);
        }
      }, 1801);
    };

    const handleSubmit = event => {
      event.preventDefault();
      event.stopPropagation();
      event.persist();
      setLoading(true);
      setServerSideTracking(serverSideTrackingOn);
      setSubmittedUid(
        hubspotId || hubspotVisitorId || hubspotLeadDetails.user_id
      );
      if (window && window.grecaptcha) window.grecaptcha.reset();
      setRecaptchaEmpty(true);
      if (dev.isLocalLead) {
        setHunting(true);
        handleEvent(event, "Local Testing... from handleSubmit to handleEvent");
        // Handle graceful hunting without spamming the api...
        // ... helps determine a new or valid updated email to search
        // @TODO: fully test all use cases in @utils/hunter with handleHunting
      } else if (
        allowHunting &&
        !huntBypass &&
        validator.isEmail(formData.email)
      ) {
        const emailHasError = !!emailError;
        const emailComplete = !!formData.email && !emailHasError;
        // const emailSame = emailComplete && formData.email === formData.email;
        // const huntedEmailMatches = hunted === emailSame;
        if (emailComplete && !hunting) {
          console.log("Verifying contact...");
          setHunting(true);
          handleHunting(event, formData.email);
        }
      } else {
        console.log("Initiating Gamma...");
        handleServer(event);
      }
      // console.log("handleSubmit done");
    };

    /* Field Validation Handlers */
    const handleNameValidate = name => {
      const fullName = nameArray(name);
      const firstName = fullName[0];
      const lastName = fullName[1];
      if (!validator.isEmpty(name) && firstName && lastName) {
        setNameError(null);
        setHasError(false);
      } else {
        setNameError(
          `<span role="img">⚠️</span> Please enter your first and last name`
        );
      }
      handleChange("name", name);
    };

    const handleCompanyValidate = company => {
      if (!validator.isEmpty(company)) {
        setCompanyError(null);
        setHasError(false);
      } else {
        setCompanyError(
          `<span role="img">⚠️</span> Please enter your company name`
        );
      }
      handleChange("company", company);
    };

    const handleEmailValidate = email => {
      if (validator.isEmail(email)) {
        setEmailError(null);
        setHasError(false);
      } else {
        setEmailError(
          `<span role="img">⚠️</span> Please enter a valid email address`
        );
      }
      handleChange("email", email);
    };

    const handleVolumeValidate = (field, value) => {
      if (value) {
        if (value > 0 && value < MIN_VAL) {
          setWaitlist(true);
          setVolumeError(volumeLowMessage);
          setVolumeTier("Low");
          setLowVolumeTier(true);
          setBgColorMain("bg-purple gradient-purple");
        } else if (value >= MIN_VAL && value <= MEDIAN_VAL_LOW) {
          setHasError(false);
          setWaitlist(formData?.userType?.value === "Homeowner");
          handleSpend(value);
          setVolumeError(null);
          setVolumeTier("Small");
          setLowVolumeTier(false);
          setBgColorMain("bg-black");
        } else if (value > MEDIAN_VAL_LOW && value <= MEDIAN_VAL_HIGH) {
          setHasError(false);
          setWaitlist(formData?.userType?.value === "Homeowner");
          handleSpend(value);
          setVolumeError(null);
          setVolumeTier("Medium");
          setLowVolumeTier(false);
          setBgColorMain("bg-black");
        } else if (value > MEDIAN_VAL_HIGH && value <= MAX_VAL) {
          setHasError(false);
          setWaitlist(formData?.userType?.value === "Homeowner");
          handleSpend(value);
          setVolumeError(null);
          setVolumeTier("Large");
          setLowVolumeTier(false);
          setBgColorMain("bg-black");
        }
      } else {
        setHasError(false);
        setWaitlist(formData?.userType?.value === "Homeowner");
        setVolumeError(`<span role="img">⚠️</span> ${volumeMessage}`);
      }
      handleChange(field, value);
    };

    const handlePhoneValidate = phone => {
      if (!validator.isEmpty(phone)) {
        if (
          validator.isMobilePhone(phone, ["en-US", "en-CA"], {
            strictMode: process.env.GATSBY_FORMS_PHONE_US_STRICT === "true",
          })
        ) {
          setPhoneError(null);
          setHasError(false);
        } else {
          setPhoneError(
            `<span role="img">⚠️</span> Please enter a valid North American phone number.`
          );
        }
      } else {
        setPhoneError(
          `<span role="img">⚠️</span> Please enter a valid North American phone number.`
        );
      }
      handleChange("phone", phone);
    };

    const handleRoleChange = option => {
      if (option.value) {
        if (option.value === "Homeowner") {
          setIsHomeowner(true);
          setHasError(true);
          setWaitlist(true);
          setVolumeError(homeownerMessage);
          setBgColorMain("bg-purple gradient-purple");
        } else if (lowVolumeTier) {
          setIsHomeowner(false);
          setVolumeError(volumeLowMessage);
          setBgColorMain("bg-purple gradient-purple");
        } else {
          setIsHomeowner(false);
          setHasError(false);
          setWaitlist(false);
          setVolumeError(null);
          setBgColorMain("bg-black");
        }
        setRoleError(null);
        handleChange("userType", option);
      } else if (!formData.userType) {
        setBgColorMain("bg-black");
        setRoleError(`<span role="img">⚠️</span> ${roleErrorMsg}`);
      }
    };

    const handleLocationChange = option => {
      if (Array.isArray(option)) {
        const validOption = locationOption => {
          return locationOption.invalid === true;
        };
        if (option[0] && option[0].value && !option.find(validOption)) {
          setLocationsError(null);
          setHasError(false);
          setWaitlist(false);
          setBgColorMain("bg-black");
        } else if (option[0] && option.find(validOption)) {
          setHasError(true);
          setWaitlist(true);
          setBgColorMain("bg-purple gradient-purple");
          setLocationsError(
            `<span role="img">⚠️</span> Lyra doesn't currently support this location`
          );
        } else {
          setHasError(true);
          setWaitlist(false);
          setBgColorMain("bg-black");
          setLocationsError(
            `<span role="img">⚠️</span> Please select the location(s) your company operates in`
          );
        }
        handleChange("locations", option);
      }
    };

    const handleRecaptchaChange = token => {
      recaptchaRef.current = token;
      recaptchaValue = token;
      setRecaptchaToken(token);
      LocalStorage.set("_recaptcha", token, false);
      setRecaptchaEmpty(emptyRecaptcha(token));
      setHubspotVisitorId(hubspotId);
    };

    /* Custom UI components and styles */
    const ContactSalesButton = () => (
      <div className="px-4 w-full">
        <button
          onClick={() => openChat("home")}
          id="contact-sales-bot-button"
          type="button"
          className="contact-sales-bot-button block w-full transition bg-white shadow text-center border-b-4 border-blue p-6 h-full hover:shadow-lift hover:translate-y-lift"
        >
          <div className="flex justify-center">
            <Icon iconName="HubspotChat" />
          </div>
          <h2 className="text-blue uppercase text-sm font-bold tracking-2 my-6">
            {enforceLang ? "Usa el asistente" : "Use the Assistant"}
          </h2>
          <div>
            <span className="open_intercom bg-black py-3 rounded-lg text-sm px-8 text-white font-bold hover:bg-blue hover:border-white border-3 border-transparent hover:opacity-100 cursor-pointer transition ease-out duration-333 translate-y-1 font-bold">
              {isWaitlisted ? "Find answers" : "Start →"}
            </span>
          </div>
        </button>
      </div>
    );
    /* Volume tooltip */
    let timeout;
    const timeoutDuration = 0;
    const buttonRef = useRef(null);
    const panelRef = useRef(null);
    const toggleMenu = ref => ref?.current?.click();
    const onMouseHover = (open, ref) => {
      clearTimeout(timeout);
      // eslint-disable-next-line no-unused-expressions
      open && (timeout = setTimeout(() => toggleMenu(ref), timeoutDuration));
    };
    const LocationTooltip = ({ message }) => {
      return (
        <Popover className="relative inline-flex">
          {({ open }) => (
            <>
              <Popover.Button
                onMouseEnter={() => onMouseHover(!open, 1)}
                ref={buttonRef}
                className={`
              ${open ? "" : "text-opacity-90"}
              group cursor-pointer inline-flex items-center px-1 text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span>
                  <Icon
                    className="h-4 w-4 cursor-pointer"
                    heroIcon="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </span>
              </Popover.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  onClick={() => onMouseHover(open, 1)}
                  onMouseLeave={() => onMouseHover(open, 1)}
                  ref={panelRef}
                  static
                  className="toggle-menu absolute z-10 -ml-4 mt-10 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                >
                  <div className="rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="bg-blue p-4">
                      <span className="block text-xs text-white inline-flex">
                        <span className="inline-flex mr-2">
                          <Icon
                            className="h-3 w-3"
                            heroIcon="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                          />
                        </span>
                        {message}
                      </span>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      );
    };
    const VolumeTooltip = ({ message }) => {
      return (
        <Popover className="relative inline-flex">
          {({ open }) => (
            <>
              <Popover.Button
                onMouseEnter={() => onMouseHover(!open, 2)}
                ref={buttonRef}
                className={`
              ${open ? "" : "text-opacity-90"}
              group cursor-pointer inline-flex items-center px-1 text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span>
                  <Icon
                    className="h-4 w-4 cursor-pointer"
                    heroIcon="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </span>
              </Popover.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  onClick={() => onMouseHover(open, 2)}
                  onMouseLeave={() => onMouseHover(open, 2)}
                  ref={panelRef}
                  static
                  className="toggle-menu absolute z-10 -ml-4 mt-10 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                >
                  <div className="rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="bg-blue p-4">
                      <span className="block text-xs text-white inline-flex">
                        <span className="inline-flex mr-2">
                          <Icon
                            className="h-3 w-3"
                            heroIcon="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                          />
                        </span>
                        {message}
                      </span>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      );
    };
    const selectStyles = {
      control: styles => ({ ...styles, cursor: "pointer" }),
      option: styles => ({ ...styles, cursor: "pointer" }),
      multiValue: styles => ({
        ...styles,
        cursor: "pointer",
      }),
      multiValueLabel: styles => ({
        ...styles,
        cursor: "default",
      }),
      multiValueRemove: styles => ({
        ...styles,
        cursor: "pointer",
      }),
    };

    const ShowForm = props => {
      useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/shell.js";
        document.body.appendChild(script);
        script.addEventListener("load", () => {
          // @TS-ignore
          if (window?.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
              region: props?.region || detect.hubspotRegion(),
              portalId: props?.portalId || detect.hubspotPortalId(),
              formId: props?.formId || detect.hubsportDefaultForm(),
              target: "#hubspotForm",
            });
          }
        });
      }, []);
      return (
        <>
          <div
            className={classNames({
              "pointer-events-none opacity-25": preventUpdates || hasSubmitted,
            })}
            style={{
              width: props?.width || "100%",
              height: props?.height || "100%",
            }}
            id="hubspotForm"
          />
        </>
      );
    };

    const EmbeddedForm = () => {
      return embedMethod && formData.form_url ? (
        <>
          <div
            className={classNames({
              "opacity-25": loading,
              "p-6": bleed,
              "p-6 sm:p-12 ": !bleed,
              "col-span-3 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2relative transition overflow-hidden": true,
            })}
          >
            {/* Already submitted */}
            <Transition
              show={hasSubmitted}
              as={Fragment}
              enter="transition ease-out duration-200 animate-pulse"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <a
                className="flex-wrap animate-pulse"
                onClick={() => openChat("home")}
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  fontSize: "1rem",
                }}
                id="already-submitted-alert"
              >
                <div
                  style={{
                    marginTop: 0,
                    fontSize: ".8rem",
                  }}
                  role="alert"
                  className="text-left bg-teal border border-teal text-white rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: enforceLang
                        ? "Ya has completado este formulario. ¿Necesitas reenviar cosas? Haga clic aquí para chatear con nosotros primero."
                        : "You have already filled out this form. Need to resend things? Click here to chat with us first.",
                    }}
                  />
                </div>
              </a>
            </Transition>
            {allowTracking ? (
              <iframe
                src={formData?.form_url}
                allowFullScreen
                title="embedHubspotForm"
                security="restricted"
                height="125%"
                width="100%"
                className={classNames({
                  "pointer-events-none opacity-25":
                    preventUpdates || hasSubmitted,
                })}
                style={{
                  marginTop: isMobile ? "-69px" : "-97px",
                  minHeight: isMobile
                    ? `${formData?.form_height + 375}px`
                    : `${formData?.form_height}px`,
                }}
              />
            ) : (
              <div>
                Your browser settings or ad-blocker are preventing this from
                &nbsp;loading. Please disable them or try
                <a
                  href={formData?.form_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  opening the form here →
                </a>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className={classNames({
            "opacity-25": loading,
            "p-6": bleed,
            "p-6 sm:p-12 ": !bleed,
            "col-span-3 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2relative transition overflow-hidden": true,
          })}
        >
          {/* Already submitted */}
          <Transition
            show={hasSubmitted}
            as={Fragment}
            enter="transition ease-out duration-200 animate-pulse"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <a
              className="flex-wrap animate-pulse"
              onClick={() => openChat("home")}
              style={{
                textDecoration: "none",
                cursor: "pointer",
                fontSize: "1rem",
              }}
              id="already-submitted-alert"
            >
              <div
                style={{
                  marginTop: 0,
                  fontSize: ".8rem",
                }}
                role="alert"
                className="text-left bg-teal border border-teal text-white rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: enforceLang
                      ? "Ya has completado este formulario. ¿Necesitas reenviar cosas? Haga clic aquí para chatear con nosotros primero."
                      : "You have already filled out this form. Need to resend things? Click here to chat with us first.",
                  }}
                />
              </div>
            </a>
          </Transition>
          <ShowForm formId={formData?.form_id} />
        </div>
      );
    };

    /* React hooks and effects (to check status of state, recaptcha, etc) */
    function checkStatus() {
      if (wasSuccessful) setCompletionMessage(true);
      clearTimeout(testTimeout);
      clearTimeout(hubspotTimeout);
      clearTimeout(serverTimeout);
    }

    useEffect(() => {
      checkStatus();
    }, []);

    useEffect(() => {
      if (isTestLead) {
        handleVolumeValidate("packageVolume", formData.packageVolume);
      }
    }, [formData.packageVolume]);

    useEffect(() => {
      recpatchaTimeout = setTimeout(() => {
        const grecaptchaObject = window?.grecaptcha;
        if (showRecaptcha && !grecaptchaObject) {
          console.log("grecaptcha:effect", !!grecaptchaObject);
          handleBrowserFailure("No recaptcha loaded on page");
        }
      }, 4444);
    }, []);

    useEffect(() => {
      if (formData?.form_url || formData?.form_id) {
        handleFormEvents(formData?.form_id);
        // eslint-disable-next-line no-unused-expressions
        window?.addEventListener("message", _event => {
          const _payload = _event?.data;
          if (
            _payload?.type === "hsFormCallback" &&
            _payload?.eventName === "onFormSubmitted"
          ) {
            const submission = _payload?.data?.submissionValues;
            console.log(`Form ${_payload?.id} has been submitted: `);
            // console.log(submission);
            const updateFormData = {
              email: submission.email,
              phone: submission.phone,
              firstName: submission.firstname,
              lastName: submission.lastname,
              company: submission["0-2/name"],
              packageVolume: submission.target_yearly_volume,
              userType: submission.hs_persona,
              locations: submission.state,
            };
            console.log(updateFormData);
            setFormData({
              ...formData,
              updateFormData,
            });
            handleSuccess(updateFormData);
          }
        });
      }
    }, []);

    /* Attach classes to Hubspot RAW forms
    useEffect(() => {
      setTimeout(() => {
        const form = document.getElementById("hubspotForm");
        const formFields = document.getElementsByClassName("hs-input");
        const formLabels = document.getElementsByTagName("label");
        if (formData?.form_id && formData?.webhook_url && form) {
          console.log("form", form);
          form.classList.add(
            "col-span-3",
            "sm:col-span-2",
            "md:col-span-2",
            "lg:col-span-2",
            "xl:col-span-2relative",
            "transition",
            "flex-grow"
          );
          setTimeout(() => {
            if (form && formFields && formFields[0]) {
              console.log("formFields", formFields);
              Array.from(formFields).forEach(item => {
                item.classList.add(
                  "text-sm",
                  "font-bold",
                  "tracking-2",
                  "text-blue",
                  "w-full",
                  "sm:w-1/3",
                  "md:w-1/4"
                );
              });
              if (form && formLabels && formLabels[0]) {
                Array.from(formLabels).forEach(item => {
                  item.classList.add(
                    "text-sm font-bold tracking-2",
                    "text-blue"
                  );
                });
              }
            }
          }, 333);
        }
      }, 111);
    }, []);
    */

    return (
      <div className="py-px relative">
        {!noShapes && (
          <>
            <div className="absolute inset-0 bg-white" />
            <div className="absolute inset-0 flex items-center justify-center">
              <ShapesPatternRepeat
                className="opacity-3 text-blue stroke-current h-full w-full"
                dimensions={[10, 10]}
              />
            </div>
          </>
        )}
        <div className="relative flex w-full justify-center items-center order-last">
          <div
            className={classNames({
              "mt-0": bleed,
              "mt-12": !bleed,
              "absolute w-3/7 translate-x-1/4 right-0 top-0 flex opacity-25": true,
            })}
          >
            {backgroundImageUrl && (
              <Image src={backgroundImageUrl} transparent />
            )}
          </div>
          {circleTop && (
            <div
              className="bg-blue-offwhite absolute -mt-48 inset-0 z-0"
              style={{
                borderRadius: "100%",
                width: "125%",
                height: "200px",
                top: "100px",
                left: "-5%",
              }}
            />
          )}
          <Container
            variant="heading"
            className={classNames({
              "my-0": bleed,
              "my-24": !bleed,
            })}
            noPadding={bleed}
          >
            <div
              className={classNames({
                "bg-purple-dark": isWaitlisted,
                "bg-blue-offwhite": bleed && !isWaitlisted && !lowVolumeTier,
                "bg-white": !bleed && !isWaitlisted && !lowVolumeTier,
                "transition shadow rounded-lg": true,
              })}
            >
              <div
                className={classNames({
                  "grid grid-cols-1 gap-0": bleed,
                  "grid grid-cols-3 gap-0": !bleed,
                })}
              >
                <div
                  className={classNames({
                    "bg-purple gradient-purple": isWaitlisted,
                    "gradient-light text-black": gradient === "light",
                    [bgColor]: !isWaitlisted && !lowVolumeTier,
                    "rounded-tl-lg rounded-tr-lg": bleed,
                    "rounded-tl-lg rounded-bl-lg": !bleed,
                    "text-white": gradient !== "light",
                    "col-span-3 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 p-4 md:px-4": true,
                  })}
                >
                  <div className="align-middle my-auto">
                    {icon && (
                      <div className="flex justify-center mb-6">
                        <Icon
                          className={classNames({
                            "text-white": gradient !== "light",
                            "text-blue": gradient === "light",
                            "w-16 h-16 my-8": true,
                          })}
                          iconName={icon}
                        />
                      </div>
                    )}
                    <h2 className="text-2xl md:text-2-5xl font-bold text-center my-8">
                      {waitlist || lowVolumeTier ? waitlistTitle : heading}
                    </h2>
                    <div className="rich-text text-center text-lg md:text-xl my-8">
                      {waitlist || lowVolumeTier ? (
                        <WaitlistSubtitle />
                      ) : (
                        <RichText content={subtitle} />
                      )}
                    </div>
                  </div>
                  {allowTracking && showMessengerButton && !isHomeowner && (
                    <ContactSalesButton />
                  )}
                </div>
                {useZapier && (formData.form_id || formData.form_url) ? (
                  <EmbeddedForm />
                ) : (
                  <form
                    // data-netlify="true"
                    name={formId || `${uid}-form`}
                    onSubmit={handleSubmit}
                    className={classNames({
                      "opacity-25": loading,
                      "p-6": bleed,
                      "p-6 sm:p-12": !bleed,
                      "col-span-3 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2relative transition": true,
                    })}
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value={`${uid}-form`}
                    />
                    <div>
                      {/* Is site under maintenance? */}
                      <Transition
                        show={isMaintenance}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <a
                          className="flex-wrap"
                          onClick={() => openChat("home")}
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            fontSize: "1rem",
                          }}
                          id="maintenance-alert"
                        >
                          <div
                            style={{
                              marginBottom: 0,
                              fontSize: ".8rem",
                            }}
                            role="alert"
                            className="text-left hover:bg-black bg-red border border-red text-white rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: enforceLang
                                  ? "Nuestro sitio se encuentra actualmente en mantenimiento, utilice el Asistente."
                                  : "Our site is currently under maintenance, please use the Assistant.",
                              }}
                            />
                          </div>
                        </a>
                      </Transition>
                      {/* Already submitted */}
                      <Transition
                        show={preventUpdates && !isMaintenance}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <a
                          className="flex-wrap"
                          onClick={() => openChat("home")}
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            fontSize: "1rem",
                          }}
                          id="already-submitted-alert"
                        >
                          <div
                            style={{
                              marginBottom: 0,
                              fontSize: ".8rem",
                            }}
                            role="alert"
                            className="text-left bg-teal border border-teal text-white rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: enforceLang
                                  ? "Ya has completado este formulario. ¿Necesitas reenviar cosas? Haga clic aquí para chatear con nosotros primero."
                                  : "You have already filled out this form. Need to resend things? Click here to chat with us first.",
                              }}
                            />
                          </div>
                        </a>
                      </Transition>
                      {/* name */}
                      <div className="transition flex flex-wrap sm:flex-no-wrap items-top my-4">
                        <div className="w-full sm:w-1/3 md:w-1/4">
                          <label
                            htmlFor="name"
                            className={classNames({
                              "text-black": bleed && !nameError,
                              "text-blue": !bleed && !nameError,
                              "text-purple": isWaitlisted,
                              "text-red": nameError,
                              "text-sm font-bold tracking-2": true,
                            })}
                          >
                            {enforceLang ? "Nombre" : "Name"}
                          </label>
                        </div>
                        <div className="flex-grow">
                          <input
                            className={classNames({
                              "focus:border-teal": !nameError,
                              "bg-red-100 focus:border-red border-red": nameError,
                              "p-3 bg-white border-blue": !bleed,
                              "p-1 bg-white border-black": bleed,
                              "bg-disabled": preventUpdates,
                              "border-purple": isWaitlisted,
                              "rounded-none border-l-4 w-full shadow transition": true,
                            })}
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            disabled={loading || preventUpdates}
                            required
                            onChange={event => {
                              handleNameValidate(event.target.value);
                            }}
                            onBlur={event => {
                              handleNameValidate(event.target.value);
                            }}
                          />
                          <Transition
                            show={!!nameError}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <div className="flex-wrap">
                              <div
                                style={{
                                  marginBottom: 0,
                                  textShadow: "1px 1px #fff",
                                  fontSize: ".65rem",
                                }}
                                role="alert"
                                className="text-left bg-red-100 border border-red-400 text-red-700 rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: nameError,
                                  }}
                                />
                              </div>
                            </div>
                          </Transition>
                        </div>
                      </div>
                      {/* email */}
                      <div className="flex flex-wrap sm:flex-no-wrap items-top my-4">
                        <div className="w-full sm:w-1/3 md:w-1/4">
                          <label
                            htmlFor="email"
                            className={classNames({
                              "text-black": bleed && !emailError,
                              "text-blue": !bleed && !emailError,
                              "text-purple": isWaitlisted,
                              "text-red": emailError,
                              "text-sm font-bold tracking-2": true,
                            })}
                          >
                            {enforceLang ? "Correo electrónico" : "Email"}
                          </label>
                        </div>
                        <div className="flex-grow">
                          <input
                            className={classNames({
                              "focus:border-teal": !emailError,
                              "bg-red-100 focus:border-red border-red": emailError,
                              "p-3 border-blue": !bleed,
                              "p-1 border-black": bleed,
                              "bg-disabled":
                                preventUpdates && !isLeadOnboarding,
                              // eslint-disable-next-line prettier/prettier
                            "shadow-none border-none bg-transparent": isLeadOnboarding,
                              "bg-white": !isLeadOnboarding,
                              "border-purple": isWaitlisted,
                              "rounded-none border-l-4 w-full shadow transition": true,
                            })}
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            disabled={
                              loading || preventUpdates || isLeadOnboarding
                            }
                            required
                            onChange={event => {
                              handleEmailValidate(event.target.value, true);
                            }}
                            onBlur={event => {
                              handleEmailValidate(event.target.value, true);
                            }}
                          />
                          <Transition
                            show={!!emailError}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <div className="flex-wrap">
                              <div
                                style={{
                                  marginBottom: 0,
                                  textShadow: "1px 1px #fff",
                                  fontSize: ".65rem",
                                }}
                                role="alert"
                                className="text-left bg-red-100 border border-red-400 text-red-700 rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: emailError,
                                  }}
                                />
                              </div>
                            </div>
                          </Transition>
                        </div>
                      </div>
                      {/* phone */}
                      {showPhoneField && (
                        <div className="transition flex flex-wrap sm:flex-no-wrap items-top my-4">
                          <div className="w-full sm:w-1/3 md:w-1/4">
                            <label
                              htmlFor="phone"
                              className={classNames({
                                "text-black": bleed && !phoneError,
                                "text-blue": !bleed && !phoneError,
                                "text-purple": isWaitlisted,
                                "text-red": !!phoneError,
                                "text-sm font-bold tracking-2": true,
                              })}
                            >
                              {enforceLang ? "Teléfono" : "Phone"}
                            </label>
                          </div>
                          <div className="flex-grow">
                            <NumberFormat
                              format="+1 ###-###-####"
                              mask="_"
                              className={classNames({
                                "focus:border-teal": !phoneError,
                                "bg-red-100 focus:border-red border-red": phoneError,
                                "p-3 bg-white border-blue": !bleed,
                                "p-1 bg-white border-black": bleed,
                                "bg-disabled": preventUpdates,
                                "border-purple": isWaitlisted,
                                "rounded-none border-l-4 w-full shadow transition": true,
                              })}
                              type="text"
                              id="phone"
                              name="phone"
                              value={formData.phone}
                              defaultValue={formData.phone}
                              allowEmptyFormatting={false}
                              disabled={loading || preventUpdates}
                              required={phoneRequired}
                              onValueChange={values => {
                                const {
                                  formattedValue,
                                  // floatValue,
                                } = values;
                                // formattedValue = $2,223 (prefix)
                                // value ie, 2223
                                handlePhoneValidate(formattedValue);
                              }}
                              onBlur={event =>
                                handlePhoneValidate(event.target.value)
                              }
                            />
                            <Transition
                              show={!!phoneError}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-200"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <div className="flex-wrap">
                                <div
                                  style={{
                                    marginBottom: 0,
                                    textShadow: "1px 1px #fff",
                                    fontSize: ".65rem",
                                  }}
                                  role="alert"
                                  className="text-left bg-red-100 border border-red-400 text-red-700 rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: phoneError,
                                    }}
                                  />
                                </div>
                              </div>
                            </Transition>
                          </div>
                        </div>
                      )}
                      {/* company */}
                      <div className="transition flex flex-wrap sm:flex-no-wrap items-top my-4">
                        <div className="w-full sm:w-1/3 md:w-1/4">
                          <label
                            htmlFor="company"
                            className={classNames({
                              "text-black": bleed && !companyError,
                              "text-blue": !bleed && !companyError,
                              "text-purple": isWaitlisted,
                              "text-red": companyError,
                              "text-sm font-bold tracking-2": true,
                            })}
                          >
                            {enforceLang ? "Compañía" : "Company"}
                          </label>
                        </div>
                        <div className="flex-grow">
                          <input
                            className={classNames({
                              "focus:border-teal": !companyError,
                              "bg-red-100 focus:border-red border-red": companyError,
                              "p-3 border-blue": !bleed,
                              "p-1 border-black": bleed,
                              "bg-disabled":
                                preventUpdates && !isLeadOnboarding,
                              "border-purple": isWaitlisted,
                              "shadow-none border-none bg-transparent": isLeadOnboarding,
                              "bg-white": !isLeadOnboarding,
                              "rounded-none border-l-4 w-full shadow transition": true,
                            })}
                            type="text"
                            id="company"
                            name="company"
                            value={formData.company}
                            disabled={
                              loading || preventUpdates || isLeadOnboarding
                            }
                            required
                            onChange={event => {
                              handleCompanyValidate(event.target.value);
                            }}
                            onBlur={event => {
                              handleCompanyValidate(event.target.value);
                            }}
                          />
                          <Transition
                            show={!!companyError}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <div className="flex-wrap">
                              <div
                                style={{
                                  marginBottom: 0,
                                  textShadow: "1px 1px #fff",
                                  fontSize: ".65rem",
                                }}
                                role="alert"
                                className="text-left bg-red-100 border border-red-400 text-red-700 rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: companyError,
                                  }}
                                />
                              </div>
                            </div>
                          </Transition>
                        </div>
                      </div>
                      {/* select locations */}
                      {collectVolume && locationOptions && (
                        <div className="transition flex flex-wrap sm:flex-no-wrap items-top my-4">
                          <div className="w-full sm:w-1/3 md:w-1/4">
                            <label
                              htmlFor="locations"
                              className={classNames({
                                "text-black": bleed && !locationsError,
                                "text-blue": !bleed && !locationsError,
                                "text-purple": isWaitlisted,
                                "text-red": locationsError,
                                "text-sm font-bold tracking-2": true,
                              })}
                            >
                              {enforceLang ? "Ubicaciones" : "Locations"}
                              <LocationTooltip
                                message={
                                  locationsTooltip ||
                                  "Select the markets that your company operates in that Lyra currently supports"
                                }
                              />
                            </label>
                          </div>
                          <div className="flex-grow">
                            <Select
                              className={classNames({
                                "focus:border-teal": !locationsError,
                                "bg-transparent focus:border-red border-red": locationsError,
                                "p-3 bg-white border-blue":
                                  !bleed && !isWaitlisted,
                                "p-1 bg-blue-offwhite border-black":
                                  bleed && !isWaitlisted,
                                "p-1 border-purple bg-black":
                                  isWaitlisted && bleed,
                                "p-3 border-purple": isWaitlisted && !bleed,
                                "form-multiselect block w-full rounded-none border-l-4 p-1 pr-0 w-full transition": true,
                              })}
                              styles={selectStyles}
                              id="locations"
                              name="locations"
                              defaultValue={selectedLocations}
                              onChange={handleLocationChange}
                              onBlur={handleLocationChange}
                              placeholder="Please select all relevant"
                              isClearable
                              isSearchable
                              options={locationOptions}
                              isDisabled={loading || preventUpdates}
                              aria-required
                              isMulti
                              required
                            />
                            <Transition
                              show={!!locationsError}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-200"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <div className="flex-wrap">
                                <div
                                  style={{
                                    marginBottom: 0,
                                    fontSize: ".65rem",
                                  }}
                                  role="alert"
                                  className={classNames({
                                    "bg-purple": isWaitlisted && locationsError,
                                    "bg-red-100 border-red text-red-700":
                                      !isWaitlisted && locationsError,
                                    "text-left text-white border border-gray-light rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 mr-4 overflow-hidden md:mb-0 mb-4": true,
                                  })}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: locationsError,
                                    }}
                                  />
                                </div>
                              </div>
                            </Transition>
                          </div>
                        </div>
                      )}
                      {/* select role */}
                      {roleOptions && (
                        <div className="transition flex flex-wrap sm:flex-no-wrap items-top my-4">
                          <div className="w-full sm:w-1/3 md:w-1/4">
                            <label
                              htmlFor="role"
                              className={classNames({
                                "text-black": bleed && !roleError,
                                "text-blue": !bleed && !roleError,
                                "text-purple": isWaitlisted,
                                "text-red": roleError,
                                "text-sm font-bold tracking-2": true,
                              })}
                            >
                              Role
                            </label>
                          </div>
                          <div className="flex-grow">
                            <Select
                              className={classNames({
                                "focus:border-teal": !roleError,
                                "bg-transparent focus:border-red border-red": roleError,
                                "p-3 bg-white border-blue":
                                  !bleed && !isWaitlisted,
                                "p-1 bg-blue-offwhite border-black":
                                  bleed && !isWaitlisted,
                                "p-1 border-purple bg-black":
                                  isWaitlisted && bleed,
                                "p-3 border-purple": isWaitlisted && !bleed,
                                "form-multiselect block w-full rounded-none border-l-4 p-1 pr-0 w-full transition": true,
                              })}
                              styles={selectStyles}
                              id="role"
                              name="role"
                              defaultValue={selectedRole}
                              onChange={handleRoleChange}
                              onBlur={handleRoleChange}
                              placeholder="Please select one"
                              isSearchable
                              options={roleOptions}
                              isDisabled={loading || preventUpdates}
                              aria-required
                              required
                            />
                            <Transition
                              show={!!roleError}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-200"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <div className="flex-wrap">
                                <div
                                  style={{
                                    marginBottom: 0,
                                    textShadow: "1px 1px #fff",
                                    fontSize: ".65rem",
                                  }}
                                  role="alert"
                                  className="text-left bg-red-100 border border-red-400 text-red-700 rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 overflow-hidden"
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: roleError,
                                    }}
                                  />
                                </div>
                              </div>
                            </Transition>
                          </div>
                        </div>
                      )}
                      {/* permit volume */}
                      {collectVolume && (
                        <div className="transition flex flex-wrap sm:flex-no-wrap items-end my-4 mt-6">
                          <div
                            className={classNames({
                              "mb-3": !volumeError,
                              "w-full sm:w-2/3 md:w-3/4": true,
                            })}
                          >
                            <label
                              htmlFor="packageVolume"
                              className={classNames({
                                "text-black": bleed && !volumeError,
                                "text-blue": !bleed && !volumeError,
                                "text-purple": isWaitlisted,
                                "text-red": volumeError || isHomeowner,
                                "text-sm font-bold tracking-2": true,
                              })}
                            >
                              {enforceLang
                                ? "Paquetes de permisos anuales"
                                : "Annual Permit Packages"}
                              <VolumeTooltip
                                message={
                                  volumeTooltip ||
                                  `${volumeLowMessage} on the Lyra platform`
                                }
                              />
                            </label>
                            <Transition
                              show={isHomeowner || !!volumeError}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-200"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <div className="flex-wrap">
                                <div
                                  style={{
                                    fontSize: ".65rem",
                                  }}
                                  role="alert"
                                  className={classNames({
                                    "bg-red": isHomeowner,
                                    "bg-purple":
                                      !!volumeError &&
                                      isWaitlisted &&
                                      !isHomeowner,
                                    "bg-red-100 border-red text-red-700":
                                      !!volumeError && !isWaitlisted,
                                    "text-left text-white border border-gray-light rounded relative text-xs transition py-1 px-3 tracking-1 c-card block items-center my-4 mr-4 overflow-hidden md:mb-0 mb-4": true,
                                  })}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: isHomeowner
                                        ? homeownerMessage
                                        : volumeError,
                                    }}
                                  />
                                </div>
                              </div>
                            </Transition>
                          </div>
                          <div className="flex-grow">
                            <NumberFormat
                              required
                              id="packageVolume"
                              name="packageVolume"
                              value={formData.packageVolume}
                              defaultValue={formData.packageVolume}
                              displayType="input"
                              type="text"
                              thousandSeparator
                              allowNegative={false}
                              isAllowed={withValueLimit}
                              allowEmptyFormatting={false}
                              thousandsGroupStyle="thousand"
                              allowLeadingZeros={false}
                              className={classNames({
                                "bg-red-100 focus:border-red border-red":
                                  volumeError && !isWaitlisted,
                                "focus:border-teal": !volumeError,
                                "p-3 bg-white border-blue": !bleed,
                                "p-1 bg-white border-black": bleed,
                                "bg-disabled": preventUpdates,
                                "border-purple": isWaitlisted,
                                "rounded-none border-l-4 w-full shadow transition text-xl": true,
                              })}
                              disabled={loading || preventUpdates}
                              onValueChange={values => {
                                const {
                                  // formattedValue,
                                  floatValue,
                                } = values;
                                // formattedValue = $2,223 (prefix)
                                // value ie, 2223
                                // handleSpend(floatValue);
                                handleVolumeValidate(
                                  "packageVolume",
                                  floatValue
                                );
                              }}
                              onBlur={event => {
                                handleVolumeValidate(
                                  "packageVolume",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* recaptcha */}
                    <div className="flex items-center justify-center">
                      {showRecaptcha && (
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={recaptchaKey}
                          onChange={handleRecaptchaChange}
                        />
                      )}
                    </div>
                    {/* submitt form */}
                    <div className="text-center mt-8">
                      {!isHomeowner && (
                        <Button
                          type="submit"
                          className={classNames({
                            "disabled:bg-gray border-gray text-black": isHomeowner,
                            "disabled:bg-purple border-purple hover:border-purple hover:text-purple": isWaitlisted,
                            "disabled:bg-blue border-white hover:border-blue": !isWaitlisted,
                            "p-1 bg-black border-black": bleed,
                            "text-sm disabled:opacity-50 disabled:cursor-default hover:bg-white hover:text-blue hover:border-blue border-3 border-transparent": true,
                          })}
                          classNsme={`${
                            isWaitlisted
                              ? "disabled:bg-purple border-purple hover:border-purple"
                              : `disabled:${bgColorMain}`
                          } disabled:bg-blue text-sm disabled:opacity-50 disabled:cursor-default hover:bg-white hover:text-blue hover:border-blue border-3 border-transparent`}
                          textClass="text-white"
                          bgClass={
                            isWaitlisted
                              ? "bg-purple"
                              : bgColorMain || "bg-blue"
                          }
                          disabled={
                            disabledButton ||
                            (showRecaptcha && !!recaptchaEmpty) ||
                            preventUpdates
                          }
                          button
                        >
                          {isWaitlisted
                            ? "Join Waitlist"
                            : buttonText || "Sign Up"}
                        </Button>
                      )}
                      <div className="text-center mt-8 text-xs text-gray italic">
                        By signing up you agree to Lyra&apos;s&nbsp;
                        <PrismicLink
                          route="/privacy-policy"
                          className="text-center transition hover:translate-y-lift m-auto"
                        >
                          privacy
                        </PrismicLink>{" "}
                        &&nbsp;
                        <PrismicLink
                          route="/data-collection-policy"
                          className="text-center transition hover:translate-y-lift m-auto"
                        >
                          data
                        </PrismicLink>{" "}
                        policies
                      </div>
                      <div
                        className="mb-2 items-center justify-center"
                        data-active={loading}
                      >
                        {loading && (
                          <div
                            style={{ margin: "auto" }}
                            className="rounded-full h-8 w-8 flex items-center justify-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-current stroke-current w-full h-full animate-spin text-blue"
                              viewBox="0 0 64 64"
                              style={{ maxWidth: "20px" }}
                            >
                              <g
                                id="Designs"
                                fill="none"
                                fillRule="evenodd"
                                stroke="none"
                                strokeWidth="1"
                              >
                                <g
                                  id="Designs"
                                  fill="none"
                                  fillRule="evenodd"
                                  stroke="none"
                                  strokeWidth="1"
                                >
                                  <g
                                    id="Lyra-About"
                                    transform="translate(-940.000000, -1103.000000)"
                                  >
                                    {" "}
                                    <rect
                                      width="1440"
                                      height="4953"
                                      x="0"
                                      y="0"
                                    />{" "}
                                    <g
                                      id="DOING-OUR-PART"
                                      transform="translate(239.000000, 528.000000)"
                                    >
                                      <g
                                        id="Molecule/ValueCard-Copy-3"
                                        transform="translate(508.000000, 520.000000)"
                                      >
                                        <g
                                          id="Atom/Icon/Empowering/Purple"
                                          transform="translate(189.000000, 57.000000)"
                                        >
                                          {" "}
                                          <rect
                                            id="Rectangle-Copy-15"
                                            width="44.7671233"
                                            height="46.0714286"
                                            x="11.4520548"
                                            y="17.1428571"
                                            fill="#8D8DE0"
                                            opacity="0.3"
                                            rx="22.3835616"
                                          />{" "}
                                          <g
                                            id="Group-28"
                                            stroke="#8D8DE0"
                                            strokeWidth="1"
                                            transform="translate(4.164384, 0.000000)"
                                          >
                                            <g id="Group-11" strokeWidth="3">
                                              {" "}
                                              <path
                                                id="Stroke-1"
                                                strokeLinecap="round"
                                                d="M36.0465729,0.441051088 L36.0465729,45.4088657"
                                              />{" "}
                                              <path
                                                id="Stroke-3"
                                                d="M36.0465729,63.3634229 L36.0465729,72.8570464"
                                              />{" "}
                                              <path
                                                id="Stroke-5"
                                                d="M8.82683686,34.265631 L0.000236612702,34.265631"
                                              />{" "}
                                              <path
                                                id="Stroke-7"
                                                d="M13.4407846,20.6532148 L5.46788294,15.9599603"
                                              />{" "}
                                              <path
                                                id="Stroke-9"
                                                d="M23.902142,11.7366585 L19.7775093,4.45239167"
                                              />{" "}
                                            </g>{" "}
                                            <path
                                              id="Stroke-12"
                                              strokeWidth="3"
                                              d="M19.7808219,65.3571429 L22.9041096,60"
                                            />{" "}
                                            <g
                                              id="Group-21"
                                              strokeWidth="3"
                                              transform="translate(5.205479, 36.428571)"
                                            >
                                              {" "}
                                              <path
                                                id="Stroke-13"
                                                d="M0.420242417,15.3264762 L6.61590005,11.7217143"
                                              />{" "}
                                              <path
                                                id="Stroke-15"
                                                d="M57.3817322,0.51552381 L66.1958125,0.51552381"
                                              />{" "}
                                              <path
                                                id="Stroke-17"
                                                d="M52.7743291,13.95 L60.7359217,18.5819048"
                                              />{" "}
                                              <path
                                                id="Stroke-19"
                                                d="M42.3276215,22.75 L46.4468761,29.9390476"
                                              />{" "}
                                            </g>{" "}
                                            <path
                                              id="Stroke-22"
                                              strokeWidth="3"
                                              d="M52.0547945,6.42857143 L48.9315068,11.7857143"
                                            />{" "}
                                            <g
                                              id="Group-27"
                                              strokeWidth="3"
                                              transform="translate(13.534247, 15.000000)"
                                            >
                                              {" "}
                                              <path
                                                id="Stroke-23"
                                                d="M51.6724757,3.81096308 L45.4680174,7.45622793"
                                              />{" "}
                                              <path
                                                id="Stroke-25"
                                                strokeLinecap="round"
                                                d="M28.7300349,0.277704655 C37.0517036,3.22569823 43.0238082,11.2785072 43.0238082,20.7508989 C43.0238082,32.7205618 33.4883163,42.4236116 21.7253524,42.4236116 C9.96238854,42.4236116 0.426896638,32.7205618 0.426896638,20.7508989 C0.426896638,11.1850883 6.51778082,3.06678973 14.9676936,0.191990369"
                                              />{" "}
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <RandomShapes opacity="0.5" seed="asdsad" />
          </Container>
        </div>
      </div>
    );
  }
);

ExtendedEmailSignup.defaultProps = {
  buttonColor: "bg-black",
  bleed: false,
};

ExtendedEmailSignup.propTypes = {
  buttonColor: PropTypes.string,
  bleed: PropTypes.bool,
};

export default ExtendedEmailSignup;
