/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useRef, useState } from "react";
import { useTransitionState } from "gatsby-plugin-transition-link/hooks";
import classNames from "classnames";
import { useCookies } from "react-cookie";
import { Power2 } from "gsap";
import { HeaderContext } from "@context";
import { refresh } from "@hubspot";
import { unWidowerClient, detect } from "@utils";
import { AppFooter, AppFooterEs } from "@layout";

const hasWindow = detect.browser().window;

const PageTransition = React.memo(({ children, theme }) => {
  const transitionState = useTransitionState();
  const [hasEntered, setHasEntered] = useState(false);
  const { mount, transitionStatus } = transitionState;
  const [POP, setPOP] = useState(null);
  const {
    setTheme,
    navOpen,
    setNavOpen,
    announcementShowing,
    setAnnouncementShowing,
    allowCookies,
    isBlog,
    tl,
    enforceLang,
  } = useContext(HeaderContext);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(["sawLyraAnnouncement"]);
  // eslint-disable-next-line no-unused-vars
  const cookie = cookies.lyraAnnouncementUpdatedAt;

  const wipeRef = useRef();
  const pageRef = useRef();

  // set theme color if ssr
  if (!hasWindow) {
    setTheme(theme);
  }

  // set header theme // close mobile nav // load annnouncement on mount
  useEffect(() => {
    setNavOpen(false);
    unWidowerClient();
    if (allowCookies && !cookie && announcementShowing === null) {
      setAnnouncementShowing(true);
    } else if (allowCookies) {
      setAnnouncementShowing(false);
    }
    if (mount || (!mount && POP !== null)) {
      setTheme(theme);
    }
  }, [hasEntered, allowCookies]);

  // handle transitions
  if (hasWindow) {
    const wipeColor =
      theme === "light"
        ? "linear-gradient(130deg, #F6FAFF, #ffffff)"
        : isBlog
        ? "linear-gradient(130deg, #757575, #333038)"
        : "linear-gradient(130deg, #133095, #2D80EF)";
    useEffect(() => {
      const baseDuration = navOpen ? 0.125 : 0;
      const { scrollY } = window;
      if (transitionStatus === "POP") {
        // ensure new value each time page loads
        setPOP(Math.random);
      }
      if (transitionStatus === "entering" || transitionStatus === "POP") {
        tl.set(
          wipeRef.current,
          {
            opacity: 1,
            background: wipeColor,
            "-webkit-mask":
              "radial-gradient(var(--R) at 50% calc(1.8*var(--size)),#000 99%,#0000 101%) calc(50% - 2*var(--size)) 0/calc(4*var(--size)) 100%, radial-gradient(var(--R) at 50% calc(-.8*var(--size)),#0000 99%,#000 101%) 50% var(--size)/calc(4*var(--size)) 100% repeat-x;",
          },
          baseDuration
        )
          .fromTo(
            wipeRef.current,
            0.5,
            { yPercent: "-100" },
            { yPercent: "0", ease: Power2.easeIn },
            baseDuration + 0.01
          )
          .to(
            wipeRef.current,
            0.333,
            {
              opacity: 0,
            },
            baseDuration + 0.35 + 0.25
          );
        tl.fromTo(
          pageRef.current,
          0.25,
          { opacity: 0 },
          { opacity: 1 },
          baseDuration + 0.35
        );
        setHasEntered(true);
      } else if (transitionStatus === "entered" && !hasEntered) {
        tl.set(pageRef.current, { opacity: 1 });
        setHasEntered(true);
      }
      if (transitionStatus === "exiting") {
        tl.set(
          pageRef.current,
          {
            y: `-${scrollY}px`,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
          },
          baseDuration + 0
        );
      }
    }, [transitionStatus]);
    useEffect(() => {
      refresh();
    }, [hasEntered]);
  }

  return (
    <>
      {/* SOLID */}
      <div
        ref={wipeRef}
        className={classNames({
          "opacity-0": hasWindow || (!mount && transitionStatus === "entry"),
          "page-wipe fixed inset-0 w-full h-full z-0 pointer-events-none": true,
        })}
      />
      {/* PAGE */}
      <main
        ref={pageRef}
        className={classNames({
          "opacity-0": hasWindow && transitionStatus === "entering",
          "overflow-hidden":
            transitionStatus === "entering" || transitionStatus === "exiting",
          "page-container z-10 relative": true,
        })}
      >
        {children}
        {enforceLang ? <AppFooterEs /> : <AppFooter />}
      </main>
    </>
  );
});

PageTransition.propDefaults = {
  theme: "dark",
};

export default PageTransition;
